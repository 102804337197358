import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Curriculum Handbook"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "curriculum-handbook"
    }}>{`Curriculum Handbook`}</h1>
    <h3 {...{
      "id": "the-2020-de-la-salle-college-curriculum-handbook-describes-the-learning-and-teaching-arrangements-for-years-5-to-12"
    }}>{`The 2020 De La Salle College Curriculum Handbook describes the learning and teaching arrangements for Years 5 to 12.`}</h3>
    <p>{`It has been designed to provide subject information to be used as part of transition/subject selection processes, taking place throughout Term 3 and early Term 4.`}</p>
    <p>{`Parents are encouraged to spend time with their son reading through the various options for the coming academic year.`}</p>
    <p><a parentName="p" {...{
        "href": "/Curriculum-Handbook-2020.pdf"
      }}>{`Curriculum Handbook 2020`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      